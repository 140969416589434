// src/context/auth-context.js
import { createContext, useState } from "react";
import { useRouter } from "next/router";

const AuthContext = createContext({});
const { Provider } = AuthContext;

const AuthProvider = ({ children }: any) => {
  const [authState, setAuthState] = useState({
    token: "",
    refresh: "",
  });

  const [working_hours_list, setworking_hours_list] = useState([]);

  const setUserAuthInfo = (token: string, refresh: string) => {
    localStorage.setItem("token", token);
    localStorage.setItem("refresh", refresh);

    setAuthState({
      token,
      refresh,
    });
  };

  // checks if the user is authenticated or not
  const isUserAuthenticated = () => !!localStorage.getItem("token");
  const value = {
    authState,
    setAuthState: (userAuthInfo: any, refresh: string) =>
      setUserAuthInfo(userAuthInfo, refresh),
    isUserAuthenticated,
    working_hours_list,
    setworking_hours_list,
  };
  const result = <Provider value={value}>{children}</Provider>;

  return result;
};

export { AuthContext, AuthProvider };
